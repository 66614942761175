/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import "./app/app.scss";

@import '~swiper/scss';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';


// placed here instead of tutorial.scss due to slide els not getting ng scoping attribute
.swiper-slide {
  display: block;
}


.md .eas-header-small {
  font-size: 16px;
  font-weight: 500;

  min-height: 20px;
}

.ios .eas-header-small {
  font-size: 18px;
  font-weight: 700;
}

.md .eas-header-medium {
  font-size: 16px;
  font-weight: 500;

  min-height: 20px;
}

.ios .eas-header-medium {
  font-size: 20px;
  font-weight: 700;
}

.md .eas-header-large {
  font-size: 20px;
  font-weight: 500;

  min-height: 20px;
}

.ios .eas-header-large {
  font-size: 20px;
  font-weight: 700;
}


ion-content.page-content,
ion-content.page-content ion-toolbar {
  --background: var(--ion-color-light);
}

.permissions-modal {
  --height: 260px;
}

ion-list.list-inset.md {
  padding-top: 0px;
  padding-bottom: 0px;

  border-radius: 12px;
}

.connect-button.md {
  --box-shadow: none;
  --border-radius: 16px;

  width: 56px !important;
}

.connect-button.md ion-label {
  display: none;
}

.connect-button.md ion-icon {
  width: 24px !important;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px;
}

@media (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

ion-refresher ion-refresher-content {
  --color:#FFF !important;
  
  .refresher-pulling-text, 
  .refresher-refreshing-text, 
  .refresher-refreshing-icon, 
  .refresher-pulling-icon {
    color: #fff !important;
  }
}

.searchbar-input.sc-ion-searchbar-md {
  border-radius: 25px;
  background-position: left 8px center;
  height: auto;
  font-size: 1.1rem;
  font-weight: 400;
  color: #04143b;// #7f8490;
  background-color: white;
  -webkit-box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1) !important;
}

ion-popover{
  ion-backdrop{
  opacity: 0.5 !important;
  }
  .popover-wrapper{
  .popover-content{
  width:200vw;
  max-width:500px;
  }
  }
}

.pop-over-style {
  --width: 285px;
  --height:570px ;
  .popover-content {
      //width: 80% !important; - this wont work or the bottom one.
      --width: 300px !important;
      --height:1500px !important;
  }
  //--background: transparent;

} 

/*
.ios,
.md {
  content-drawer {
    width: 100%;
    height: 80%;
    //height: auto;
    //position: relative;
    //overflow:auto;
    position: absolute;
    z-index: 10 !important;
    //box-shadow: 0px -4px 22px -8px rgba(0, 0, 0, 0.75);
    border-radius: 15px;
    color: #001232;
    background: white;
    padding-bottom: 50px;
    bottom: auto;
    background-color: #34495e !important;
    
   
  }
}  
*/


