/*
ion-tab-bar {
    --background: transparent;
    background-image: linear-gradient(90deg, rgba(49,46,73) 0%, rgba(49,46,73) 50%, rgba(49,46,73) 100%);
    }

ion-toolbar-bar {
    --background: transparent;
    background-image: linear-gradient(90deg, rgba(3,32,85) 0%, rgba(3,32,85) 50%, rgba(3,32,85) 100%);
}    
.toolbar-title-md {
    color: #fff; 
} 
*/       
/*
ion-tab-bar {
    --background: transparent;
    background-image: linear-gradient(90deg, rgba(225,63,113) 0%, rgba(169,57,106) 50%, rgba(114,53,100) 100%);
    }  

@font-face {
    font-family: AppFont;
    src: url("../assets/fonts/roboto-light.ttf");
    }
    
    * {
      font-family: AppFont;
    }
    */

    @font-face {
        font-family: AppFont;
        src: url("../assets/fonts/noto-sans-regular.ttf");
        }
        
        * {
          font-family: AppFont;
        }    

  

   